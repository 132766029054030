<template>
  <div :style="getElementStyle">

    <div v-if="!isDefalutPos">
      <div style="display: flex; align-items: unset;">
        <label for="text" style="white-space: nowrap; margin-right: 5px;">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px;"
        >
          *
        </span>
        <span> <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i></span>
        <span v-if="data.description" style="margin-right: 5px;">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>
        <span
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
           class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </span>
      <span>
        <div class="vue-phone-validator">
          <VuePhoneNumberInput
            :style="getStyle"
            v-if="data.countryDisable == true"
            :onlyCountries="[data.defaultCountry]"
            :default-country-code="data.defaultCountry"
            required
            v-model="phoneNo"
            @update="onUpdate"
          />
          <VuePhoneNumberInput
            :style="getStyle"
            v-else
            :default-country-code="data.defaultCountry"
            required
            v-model="phoneNo"
            @update="onUpdate"
          />
        </div>
      </span>
      </div>
    </div>

    <el-row align="middle" v-else>
      <el-col v-if="!isInDataTable" :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24" :style="computedStyles">
        <label for="text">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <!-- <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <span v-if="data.description">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>
      </el-col> -->
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
           class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>

      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <div class="vue-phone-validator">
          <VuePhoneNumberInput
            :style="getStyle"
            v-if="data.countryDisable == true"
            :onlyCountries="[data.defaultCountry]"
            :default-country-code="data.defaultCountry"
            required
            v-model="phoneNo"
            @update="onUpdate"
          />
          <VuePhoneNumberInput
            :style="getStyle"
            v-else
            :default-country-code="data.defaultCountry"
            required
            v-model="phoneNo"
            @update="onUpdate"
          />
        </div>

        <!-- <p
                  class="error"
                  v-if="error"
                >{{errorMsg}}</p> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {
    VuePhoneNumberInput: () => import("vue-phone-number-input")
  },
  props: ["data", "value", "isActive", "isDataTableField","isInDataTable"],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = 'flex';

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    //gayatri starts
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }

      // return isDefalutPosq;
    }
    //ends
  },
  data() {
    return {
      phoneNo: "",
      countryInfo: {},
      validations: [],
      isDefalutPosq: true,
      showLabel: true,
      error: true,
      errorMsg: ""
    };
  },
  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    onUpdate(payload) {
      if (!payload.isValid) {
        this.error = true;
        this.errorMsg = `Invalid ${this.data.label}`;
      } else {
        this.error = false;
        this.errorMsg = "";
        this.countryInfo = payload;
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>

<style lang="scss">
.error {
  color: red;
}
.country-selector__input {
  background-color: #eff3fa !important;
  height: 50px !important;
  -webkit-box-shadow: 0px 1px 2px #ddebfa !important;
  box-shadow: 0px 1px 2px #ddebfa !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1.1em !important;
  letter-spacing: 0.5px !important;
}
.input-tel__input {
  background-color: #eff3fa !important;
  height: 50px !important;
  -webkit-box-shadow: 0px 1px 2px #ddebfa !important;
  box-shadow: 0px 1px 2px #ddebfa !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1.1em !important;
  letter-spacing: 0.5px !important;
}
</style>